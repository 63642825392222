<template>
  <div class="my-5 container">
    <div class="text-main">
      <h2 class="font-weight-bolder">{{ $t("header.map") }}</h2>
    </div>
    <div class="mt-5 map-container">
      <div ref="map" id="map" style="width: 100%; height: 600px"></div>
      <div class="search-field-container">
        <div class="position-relative">
          <input
            id="search-field"
            type="text"
            placeholder="Search location"
            class="form-control search-field"
          />
          <b-spinner
            v-if="fetching"
            label="Spinning"
            class="search-field-spinner"
          ></b-spinner>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { initPlaces, removeInstance } from "../helpers/map";
import { mapState, mapActions } from "vuex";
export default {
  name: "ReservationSuccess",
  data() {
    return {
      map: null,
      markers: [],
      propertiesCountData: 0,
      totalPropertiesWindow: null,
      continentPropertiesWindow: [],
      countryPropertiesWindow: [],
    };
  },
  async mounted() {
    window.vueApp = this;
    this.initMap();
    initPlaces("search-field", ({ latitude, longitude }) => {
      this.fetchProperties(latitude, longitude);
    });
    const response = await this.countProperties();
    const { data } = response.data;
    this.propertiesCountData = data;
  },
  beforeDestroy() {
    removeInstance();
  },
  computed: {
    ...mapState("property", ["properties", "fetching"]),
  },
  methods: {
    ...mapActions("property", ["searchProperties", "countProperties"]),
    initMap() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(
          (position) => {
            const userLat = position.coords.latitude;
            const userLng = position.coords.longitude;

            if (typeof google !== "undefined") {
              this.setMapWithConfig(userLat, userLng, 12);
            } else {
              console.error("Google Maps API is not loaded.");
            }
          },
          () => {
            console.error("Geolocation service failed.");
            this.initFallbackMap();
          }
        );
      } else {
        console.error("Your browser doesn't support geolocation.");
        this.initFallbackMap();
      }
    },
    initFallbackMap() {
      if (typeof google !== "undefined") {
        this.setMapWithConfig(-34.397, 150.644, 12);
      } else {
        console.error("Google Maps API is not loaded.");
      }
    },
    setMapWithConfig(lat, lng, zoom) {
      // eslint-disable-next-line no-undef
      this.map = new google.maps.Map(this.$refs.map, {
        center: { lat, lng },
        scrollwheel: true,
        zoom,
        mapId: "c75a5c4a3741e065",
      });

      const self = this;
      // eslint-disable-next-line no-undef
      google.maps.event.addListener(self.map, "zoom_changed", function () {
        const zoomLevel = self.map.getZoom();
        if (zoomLevel <= 1 && !self.totalPropertiesWindow) {
          self.clearMarkers();
          // eslint-disable-next-line no-undef
          self.totalPropertiesWindow = new google.maps.InfoWindow({
            content: `
              <div style="font-weight: 500;">You can sleep in ${self.propertiesCountData.world} properties</div>
              <button style="
                    border: none;
                    margin-top: 10px;
                    background: none;
                    padding: 0;
                    color: #0000de;
                    text-decoration: underline;
                  "
                  onclick="window.vueApp.handleInfoWindowZoomLevelChange(2)"
                >
                  View Continents
                </button>
            `,
          });

          self.totalPropertiesWindow.setPosition({ lat: 0, lng: 0 });
          self.totalPropertiesWindow.open(self.map);
          // eslint-disable-next-line no-undef
          google.maps.event.addListener(
            self.totalPropertiesWindow,
            "closeclick",
            function () {
              self.totalPropertiesWindow = null;
            }
          );
        }

        if (zoomLevel > 1 && self.totalPropertiesWindow) {
          self.totalPropertiesWindow.close();
          self.totalPropertiesWindow = null;
        }

        if (
          zoomLevel >= 2 &&
          zoomLevel <= 3 &&
          !self.continentPropertiesWindow.length
        ) {
          self.clearMarkers();
          self.propertiesCountData.continent.forEach((continent) => {
            // eslint-disable-next-line no-undef
            const pin = new google.maps.marker.PinElement({
              background: "#ff762b",
              borderColor: "#ff762b",
              glyphColor: "white",
              scale: 1.2,
            });

            // eslint-disable-next-line no-undef
            const marker = new google.maps.marker.AdvancedMarkerElement({
              position: { lat: continent.latitude, lng: continent.longitude },
              map: self.map,
              title: continent.name,
              content: pin.element,
            });

            // eslint-disable-next-line no-undef
            const infoWindow = new google.maps.InfoWindow({
              content: `
                <div style="font-weight: 500;">We have ${continent.noOfPropertiesWithDistributorType.toLocaleString()} properties in ${continent.name}</div>
                <button style="
                      border: none;
                      margin-top: 10px;
                      background: none;
                      padding: 0;
                      color: #0000de;
                      text-decoration: underline;
                    "
                    onclick="window.vueApp.handleInfoWindowZoomLevelChange(4, ${continent.latitude}, ${continent.longitude})"
                  >
                    View Countries
                  </button>
              `,
            });

            marker.addListener("click", () => infoWindow.open(self.map, marker));

            self.continentPropertiesWindow.push(marker);
          });
        }

        if ((zoomLevel < 2 || zoomLevel > 3) && self.continentPropertiesWindow.length) {
          self.continentPropertiesWindow.forEach((marker) => {
            marker.setMap(null)
          });
          self.continentPropertiesWindow = [];
        }

        if (
          zoomLevel >= 4 &&
          zoomLevel <= 7 &&
          !self.countryPropertiesWindow.length
        ) {
          self.clearMarkers();
          self.propertiesCountData.country.forEach((country) => {
            // eslint-disable-next-line no-undef
            const pin = new google.maps.marker.PinElement({
              background: "#ff762b",
              borderColor: "#ff762b",
              glyphColor: "white",
              scale: 1.2,
            });

            // eslint-disable-next-line no-undef
            const marker = new google.maps.marker.AdvancedMarkerElement({
              position: { lat: country.latitude, lng: country.longitude },
              map: self.map,
              title: country.name,
              content: pin.element,
            });

            // eslint-disable-next-line no-undef
            const infoWindow = new google.maps.InfoWindow({
              content: `
                <div style="font-weight: 500;">We have ${country.noOfPropertiesWithDistributorType.toLocaleString()} properties in ${country.name}</div>
                <button style="
                    border: none;
                    margin-top: 10px;
                    background: none;
                    padding: 0;
                    color: #0000de;
                    text-decoration: underline;
                  "
                  onclick="window.vueApp.handleInfoWindowPropertiesSearch(${country.latitude}, ${country.longitude}, 200)"
                >
                  View Properties
                </button>
              `,
            });

            marker.addListener("click", () => {
              infoWindow.open(self.map, marker);
            });

            self.countryPropertiesWindow.push(marker);
          });
        }

        if ((zoomLevel < 4 || zoomLevel > 7) && self.countryPropertiesWindow.length) {
          self.countryPropertiesWindow.forEach((marker) => {
            marker.setMap(null)
          });
          self.countryPropertiesWindow = [];
        }
      });
    },
    async handleInfoWindowZoomLevelChange(zoom, latitude = 0, longitude = 0) {
      // eslint-disable-next-line no-undef
      this.map.setCenter(new google.maps.LatLng(latitude, longitude));
      this.map.setZoom(zoom);
    },
    async handleInfoWindowPropertiesSearch(latitude, longitude, radius = 100) {
      await this.fetchProperties(latitude, longitude, radius, 8);
    },
    async fetchProperties(latitude, longitude, radius = 100, zoom = 15) {
      if (latitude && longitude) {
        await this.searchProperties({
          latitude,
          longitude,
          radius,
          page: 0,
          perPage: 1000,
        });

        this.clearMarkers();
        this.countryPropertiesWindow.forEach((marker) => {
          marker.setMap(null)
        });
        this.continentPropertiesWindow.forEach((marker) => {
          marker.setMap(null)
        });
        // eslint-disable-next-line no-undef
        this.map.setCenter(new google.maps.LatLng(latitude, longitude));
        this.map.setZoom(zoom);

        const geoCodeData = this.properties.content.map(
          ({ content: property }) => {
            if (property.media) {
              property.media
                .map((m) => {
                  m.order = m.sortOrderMain || Number.MAX_VALUE;
                  return m;
                })
                .sort((a, b) => a.order - b.order)
                .slice(0, 4);
            }

            let ratingValue = property.googleReviews?.googleRating || 0;
            let ratingCount =
              property.googleReviews?.googleNumberOfReviews || 0;
            ratingValue = parseFloat(
              ratingValue > 0 ? (ratingValue / 2).toFixed(1) : ratingValue
            );

            return {
              id: property.id,
              name: property.name,
              ...property.geoCode,
              distributorType: property.distributorType,
              ratingValue,
              ratingCount,
              address: property.addresses.length ? property.addresses[0] : null,
              image: property.media.length
                ? property.media[0].url
                : "/images/property-default-image.jpg",
            };
          }
        );
        this.displayMarkers(geoCodeData);
      }
    },
    async displayMarkers(geoCodeData) {
      geoCodeData.forEach((item) => {
        // eslint-disable-next-line no-undef
        const pin = new google.maps.marker.PinElement({
          background: "#ff762b",
          borderColor: "#ff762b",
          glyphColor: "white",
          scale: 1.2,
        });

        // eslint-disable-next-line no-undef
        const marker = new google.maps.marker.AdvancedMarkerElement({
          position: { lat: item.latitude, lng: item.longitude },
          map: this.map,
          title: item.name + "--" + item.type,
          content: pin.element,
        });

        let propertyType;
        if (item.distributorType === "A") {
          propertyType = "Instant booking, real-time";
        } else if (item.distributorType === "B") {
          propertyType = "Instant booking";
        } else {
          propertyType = "Booking on request";
        }

        let ratingHtml = "";
        for (let i = 1; i <= 5; i++) {
          ratingHtml += `<i class="fa fa-star ${
            i <= item.ratingValue ? "text-warning" : "text-muted"
          }"></i>`;
        }

        if (item.ratingCount) {
          ratingHtml += `<span style="margin-left: 5px;">(${item.ratingCount})</span>`;
        }

        // eslint-disable-next-line no-undef
        const geocoder = new google.maps.Geocoder();

        geocoder.geocode(
          { location: { lat: item.latitude, lng: item.longitude } },
          (results, status) => {
            let address = item.address;
            if (status === "OK" && results[0]) {
              address = results[0].formatted_address;
            }
            // eslint-disable-next-line no-undef
            const infoWindow = new google.maps.InfoWindow({
              content: `
            <a target="_blank" href="/listing/${item.id}"><img src="${item.image}" style="width: 200px; margin-bottom: 5px;" /></a>
            <h5><a target="_blank" href="/listing/${item.id}">${item.name}</a></h5>
            <div style="padding-bottom: 5px;">${address}</div>
            <div style="padding-bottom: 5px;">Property ID: ${item.id}</div>
            <div style="padding-bottom: 10px;">Property Type: ${propertyType}</div>
            <div style="display: flex; justify-content: space-between; align-items: center;">
              <div>${ratingHtml}</div>
              <div>
                <a target="_blank" href="/listing/${item.id}">
                  <button style="padding: 5px 10px; color: white; background: #ff762b; border: none; border-radius: 3px;">Book Now</button>
                </a>
              </div>
            </div>
          `,
            });

            marker.addListener("click", () => {
              infoWindow.open(this.map, marker);
            });

            this.markers.push(marker);
          }
        );
      });
    },
    clearMarkers() {
      this.markers.forEach((marker) => marker.setMap(null));
      this.markers = [];
    },
  },
};
</script>

<style scoped>
.map-container {
  position: relative;
}
.search-field-container {
  position: absolute;
  top: 9px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 5;
}
.search-field {
  width: 300px;
}
.search-field-spinner {
  position: absolute;
  right: 5px;
  top: 8px;
  width: 25px;
  height: 25px;
  color: #ff762b;
}
</style>
